import {GooglePlusOutlined} from '@ant-design/icons';
import {Button, Divider, Flex, Form, Input, Typography, notification} from 'antd';
import {FC, Fragment, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {ROUTE_CONFIG} from 'src/routes/config';
import AuthenService from 'src/services/authentication';
import {ISignInPayload} from 'src/services/authentication/type';
import {useAppDispatch} from 'src/stores/utils';
import {EUserRole} from 'src/utils/common/enum';
import {signInWithGooglePopup} from 'src/utils/firebase';
import {showNotiErr} from 'src/utils/notification';
import {validateEmail} from '../Authen.utils';
import {setIsLogin, setTokens, setUser} from '../store';
import AddPhoneModal from './components/addPhoneModal';
//
const {Text} = Typography;
const authenService = new AuthenService();
//
const SignInScreen: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  //
  const [loadingGoogle, setLoadingGoogle] = useState<boolean>(false);
  const [loadingSignIn, setLoadingSignIn] = useState<boolean>(false);
  const [showAddPhone, setShowAddPhone] = useState<boolean>(false);
  //
  const handleToggleAddPhone = () => {
    setShowAddPhone(prev => !prev);
  };
  //
  const handleSignInGoogle = async () => {
    setLoadingGoogle(true);
    signInWithGooglePopup()
      .then(res => {
        if (res && res.user.uid) {
          res.user
            .getIdToken()
            .then(async token => {
              let oAuthRes = await authenService.signInGoogle({
                accessToken: token,
                displayName: res.user.displayName || '',
              });
              if (oAuthRes && oAuthRes.code === 200) {
                const {data} = oAuthRes;
                const {tokens, requireVerifyPhoneNumber, info} = data;
                const {phoneNumber} = info;
                if (tokens) {
                  dispatch(setTokens(tokens));
                  dispatch(setIsLogin(true));
                }
                dispatch(setUser(info));

                if (requireVerifyPhoneNumber) {
                  // Chưa xác thực số điện thoại
                  if (phoneNumber) {
                    handleSendOTP(phoneNumber);
                  } else {
                    handleToggleAddPhone();
                  }
                } else {
                  notification.success({
                    message: 'Đăng nhập thành công',
                  });
                  navigate(ROUTE_CONFIG.HOME);
                }
              }
            })
            .catch(err => {
              showNotiErr(err);
            })
            .finally(() => setLoadingGoogle(false));
        }
      })
      .catch(err => showNotiErr(err))
      .finally(() => setLoadingGoogle(false));
  };
  const onFinish = async (values: ISignInPayload) => {
    setLoadingSignIn(true);
    authenService
      .signIn(values)
      .then(res => {
        const {data} = res;
        const {tokens, requireVerifyPhoneNumber, info} = data;
        const {active, phoneNumber} = info;
        if (tokens) {
          dispatch(setTokens(tokens));
          if (active) {
            dispatch(setIsLogin(true));
            dispatch(setUser(info));
          }
        }

        // Check xem đã xác thực điện thoại chưa
        if (requireVerifyPhoneNumber) {
          // Chưa xác thực số điện thoại
          handleSendOTP(phoneNumber);
        } else {
          notification.success({
            message: 'Đăng nhập thành công',
            description: res.message,
          });
          navigate(ROUTE_CONFIG.HOME);
        }
      })
      .catch(err => {
        showNotiErr(err);
      })
      .finally(() => {
        setLoadingSignIn(false);
      });
  };
  const handleSendOTP = async (phoneNumber: string) => {
    notification.warning({
      message: 'Cảnh báo',
      description: 'Số điện thoại chưa được xác thực, vui lòng xác thực số điện thoại',
    });
    // Gửi OTP xác nhận
    authenService
      .sendOTP({
        phoneNumber: phoneNumber,
      })
      .then(otpRes => {
        const {data} = otpRes;
        const {requestToken, retryAfterSeconds} = data;
        // Pass data sang OTP
        navigate(ROUTE_CONFIG.OTP, {
          state: {
            phoneNumber: phoneNumber,
            requestToken,
            retryAfterSeconds,
          },
        });
      })
      .catch(err => {
        showNotiErr(err);
      });
  };
  return (
    <Fragment>
      <Flex vertical align="center" gap="small">
        <Divider style={{marginBlock: 5}}>
          <Text>Đăng nhập với tài khoản</Text>
        </Divider>
        <Button
          loading={loadingGoogle}
          onClick={handleSignInGoogle}
          type="primary"
          danger
          icon={<GooglePlusOutlined />}>
          Đăng nhập bằng tài khoản Google+
        </Button>
        <Divider style={{marginBlock: 5}}>
          <Text>Hoặc</Text>
        </Divider>
        <Form
          name="signin_form"
          labelCol={{span: 24}}
          wrapperCol={{span: 24}}
          initialValues={{
            email: '',
            password: '',
          }}
          autoComplete="off"
          onFinish={onFinish}>
          <Form.Item<ISignInPayload>
            label="Email"
            name="email"
            rules={[{required: true, message: 'Email là bắt buộc!'}, validateEmail]}>
            <Input placeholder="Nhập email" />
          </Form.Item>
          <Form.Item<ISignInPayload>
            label="Mật khẩu"
            name="password"
            rules={[{required: true, message: 'Mật khẩu là bắt buộc!'}]}
            // extra={
            //   <Flex justify="flex-end">
            //     <Button type="link">Quên mật khẩu?</Button>
            //   </Flex>
            // }
          >
            <Input.Password placeholder="Nhập mật khẩu" />
          </Form.Item>
          <Button loading={loadingSignIn} type="primary" htmlType="submit" block>
            Đăng nhập
          </Button>
        </Form>
        <Text>
          Bạn chưa có tài khoản? <Link to={ROUTE_CONFIG.SIGNUP}>Đăng ký</Link>
        </Text>
      </Flex>
      <AddPhoneModal open={showAddPhone} onCancel={handleToggleAddPhone} />
    </Fragment>
  );
};
export default SignInScreen;
