import {Modal} from 'antd';
import {FC} from 'react';
import useWindowSize from 'src/hooks/useWindowSize';
import {IBuildingInfo} from 'src/services/building/type';
interface IProps {
  show: boolean;
  onHide: () => void;
  buildingDetail: IBuildingInfo | null;
}
const View360Modal: FC<IProps> = props => {
  const {onHide, show, buildingDetail} = props;
  const {windowSize} = useWindowSize();
  const {view360Link} = buildingDetail || {};
  return (
    <Modal
      width="auto"
      centered
      open={show}
      onCancel={onHide}
      footer={[]}
      modalRender={() => (
        <div>
          <iframe
            frame-ancestors={view360Link || ''}
            style={{width: windowSize.width * 0.8, height: windowSize.height * 0.7}}
            id="fancybox_iframe_7_0"
            allow="autoplay; fullscreen"
            src={view360Link || ''}
            data-ready="true"></iframe>
        </div>
      )}></Modal>
  );
};
export default View360Modal;
