import {
  BellOutlined,
  HeartOutlined,
  ImportOutlined,
  LoginOutlined,
  LogoutOutlined,
  MenuOutlined,
  UserOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import {Avatar, Badge, Button, Divider, Flex, Layout, Popover, Typography} from 'antd';
import {FC} from 'react';
import {Outlet, useNavigate} from 'react-router-dom';
import useWindowSize from 'src/hooks/useWindowSize';
import {ROUTE_CONFIG} from 'src/routes/config';
import {setIsLogin, setTokens, setUser} from 'src/screens/authentication/store';
import {openMobileMenu} from 'src/stores/common/layout.slice';
import {useAppDispatch, useAppSelector} from 'src/stores/utils';
import {checkIsAdmin, checkIsSuperAdmin} from 'src/utils/common/function';
import MenuConponent from './Menu';
import MobileMenu from './MobileMenu';
import './styles.scss';
//
const {Sider, Content, Header} = Layout;
const {Title} = Typography;
//

const LayoutComponent: FC = () => {
  //
  const navigate = useNavigate();
  // Hook
  const {isMobileMode} = useWindowSize();
  const authState = useAppSelector(state => state.auth);
  const {user, isLogin} = authState;
  const dispatch = useAppDispatch();
  //

  const handleLogout = () => {
    dispatch(setIsLogin(false));
    dispatch(setUser(null));
    dispatch(setTokens({accessToken: '', refreshToken: ''}));
    navigate(ROUTE_CONFIG.SIGNIN);
  };
  const handleNavigateProfile = () => {
    navigate(ROUTE_CONFIG.PROFILE);
  };
  const handleNavigateDecentralization = () => {
    navigate(ROUTE_CONFIG.DECENTRALIZATION);
  };
  const handleNavigateUserEvent = () => {
    navigate(ROUTE_CONFIG.USER_EVENT);
  };
  const signedInPopOverContent = () => {
    return (
      <div>
        <Flex align="center" gap="small">
          <Badge dot status="success">
            <Avatar
              size={36}
              src={<img src={user?.photo ? user.photo : require('../../assets/logo/logo.jpg')} alt="avatar" />}
            />
          </Badge>
          {user ? <p>{user.displayName}</p> : <></>}
        </Flex>
        <div>
          <Button onClick={handleNavigateProfile} type="text" shape="default" icon={<UserOutlined />}>
            Hồ sơ cá nhân
          </Button>
        </div>

        {checkIsSuperAdmin() ? (
          <div>
            <Button
              onClick={handleNavigateDecentralization}
              type="text"
              shape="default"
              icon={<UsergroupAddOutlined />}>
              Phân quyền
            </Button>
          </div>
        ) : (
          <></>
        )}
        {checkIsAdmin() || checkIsSuperAdmin() ? (
          <div>
            <Button onClick={handleNavigateUserEvent} type="text" shape="default" icon={<ImportOutlined />}>
              Căn hộ được quan tâm
            </Button>
          </div>
        ) : (
          <></>
        )}
        <Divider style={{marginTop: 5, marginBottom: 5}} />
        <Button onClick={handleLogout} type="text" shape="default" icon={<LogoutOutlined />}>
          Đăng xuất
        </Button>
      </div>
    );
  };
  const guestPopOverContent = () => {
    return (
      <div>
        <Flex align="center" gap="small">
          <Avatar size={36} src={<img src={require('../../assets/logo/logo.jpg')} alt="avatar" />} />
          <p>Xin chào, Khách</p>
        </Flex>
        <Divider style={{marginTop: 5, marginBottom: 5}} />
        <Button type="text" shape="default" icon={<LoginOutlined />} href="/signIn">
          Đăng nhập
        </Button>
      </div>
    );
  };

  return (
    <Layout style={{height: '100vh', width: '100vw', overflow: 'hidden'}}>
      <Header style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
        <Flex align="center" justify="space-between">
          {isMobileMode ? (
            <Button
              type="text"
              shape="default"
              icon={<MenuOutlined style={{fontSize: 20}} onClick={() => dispatch(openMobileMenu())} />}
            />
          ) : (
            <Title level={3}>Đông Đô Land</Title>
          )}
          <Flex align="center" gap="small">
            <Button type="text" shape="default" icon={<BellOutlined style={{fontSize: 20}} />} />
            <Button type="text" shape="default" icon={<HeartOutlined style={{fontSize: 20}} />} />
            <Popover
              content={isLogin ? signedInPopOverContent : guestPopOverContent}
              title=""
              trigger="click"
              arrow={false}
              placement="bottomLeft">
              <Button
                type="text"
                shape="default"
                icon={
                  isLogin ? (
                    <Avatar
                      size={36}
                      src={<img src={user?.photo ? user.photo : require('../../assets/logo/logo.jpg')} alt="avatar" />}
                    />
                  ) : (
                    <Avatar size={30} icon={<UserOutlined />} />
                  )
                }
              />
            </Popover>
          </Flex>
        </Flex>
      </Header>

      <Layout>
        {isMobileMode ? (
          <MobileMenu />
        ) : (
          <Sider theme="light" className="sider-container" width={244}>
            <MenuConponent />
          </Sider>
        )}
        <Content className="app-content-container">
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};
export default LayoutComponent;
