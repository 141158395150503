import {Button, Flex, notification} from 'antd';
import {FC, Fragment, useState} from 'react';
import {IBuildingInfo} from 'src/services/building/type';
import View360Modal from './view360Modal';
import useWindowSize from 'src/hooks/useWindowSize';
interface IProps {
  buildingDetail: IBuildingInfo | null;
}
const BuildingToolbar: FC<IProps> = props => {
  const {buildingDetail} = props;
  //
  const {isMobileMode} = useWindowSize();
  const [show360View, setShow360View] = useState<boolean>(false);
  //
  const handleOpenView360 = () => {
    if (buildingDetail) {
      const {view360Link} = buildingDetail;
      if (view360Link) {
        setShow360View(true);
      } else {
        notification.warning({message: 'Chưa có đường dẫn sa bàn 360 của toà nhà này!'});
      }
    } else return;
  };
  const handleCloseView360 = () => {
    setShow360View(false);
  };
  const handleOpenLinkPriceSheet = () => {
    if (buildingDetail) {
      const {priceSheetLink} = buildingDetail;
      if (priceSheetLink) {
        window.open(priceSheetLink, '_blank');
      } else {
        notification.warning({message: 'Chưa có đường dẫn phiếu tính giá!'});
      }
    } else return;
  };
  const handleOpenLinkPolicy = () => {
    if (buildingDetail) {
      const {salesPolicyLink} = buildingDetail;
      if (salesPolicyLink) {
        window.open(salesPolicyLink, '_blank');
      } else {
        notification.warning({message: 'Chưa có đường dẫn chính sách bán hàng!'});
      }
    } else return;
  };
  return (
    <Fragment>
      <Flex align="center" justify="center" gap={10} wrap="wrap">
        <Button block={isMobileMode} onClick={handleOpenView360} size="large">
          Xem sa bàn ảo 360
        </Button>
        <Button block={isMobileMode} size="large" onClick={handleOpenLinkPolicy}>
          Chính sách bàn hàng
        </Button>
        <Button block={isMobileMode} size="large" onClick={handleOpenLinkPriceSheet}>
          Phiếu tính giá
        </Button>
      </Flex>
      <View360Modal show={show360View} onHide={handleCloseView360} buildingDetail={buildingDetail} />
    </Fragment>
  );
};
export default BuildingToolbar;
