import {AntDesignOutlined, HomeOutlined, MessageOutlined} from '@ant-design/icons';
import {Button, Col, Divider, Flex, Modal, Row, Typography, notification} from 'antd';
import {FC, Fragment, useEffect, useMemo, useState} from 'react';
import {Link} from 'react-router-dom';
import useWindowSize from 'src/hooks/useWindowSize';
import {IProjectInfo} from 'src/services/project/type';
import StockService from 'src/services/stock';
import {IStockInfo} from 'src/services/stock/type';
import {useAppSelector} from 'src/stores/utils';
import {
  checkIsAdmin,
  checkIsSuperAdmin,
  convertMoney,
  findExcelObjectByMultiKey,
  findExcelObjectContainKey,
} from 'src/utils/common/function';
import {showNotiErr} from 'src/utils/notification';
//
interface IProps {
  selectedCell: HTMLTableCellElement | null;
  show: boolean;
  onHide: () => void;
  selectedStockCode: string;
  projectId: string;
  buildingId: string;
  canEdit?: boolean;
  projectDetail: IProjectInfo | null;
}
//
const {Title, Text, Paragraph} = Typography;
const stockService = new StockService();
//
const DetailModal: FC<IProps> = props => {
  const {windowSize} = useWindowSize();
  const {isLogin} = useAppSelector(state => state.auth);
  //
  const {show, onHide, selectedStockCode, projectId, selectedCell, projectDetail} = props;
  //
  const cellPosition = useMemo(() => {
    if (selectedCell) {
      const cellStyle = selectedCell.getBoundingClientRect();
      const {top, right, left} = cellStyle;
      return {
        top: top - windowSize.height * 0.3,
        right: right - windowSize.width * 0.3,
        left: left - windowSize.width * 0.3,
      };
    } else return {};
  }, [selectedCell]);
  //
  const [stockInfo, setStockInfo] = useState<IStockInfo | null>(null);
  const [loadingStock, setLoadingStock] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');
  //
  useEffect(() => {
    getStockInfo();
  }, [selectedStockCode, show, value]);

  //
  const getStockInfo = async () => {
    if (show) {
      setLoadingStock(true);
      stockService
        .getDetailByCode(projectId, selectedStockCode ? selectedStockCode : value)
        .then(res => {
          const {data} = res;
          setStockInfo(data);
        })
        .catch(err => {})
        .finally(() => setLoadingStock(false));
    } else return;
  };
  //
  const onLinkClick = async (link: string, fieldLabel: string) => {
    if (isLogin) {
      window.open(link, '_blank');
      // Gửi event quan tâm
      if (!checkIsAdmin() && !checkIsSuperAdmin()) {
        stockService
          .sendClickEvent(projectId, selectedStockCode, {eventName: 'clickLink', eventValue: `Quan tâm ${fieldLabel}`})
          .then(res => {})
          .catch(err => showNotiErr(err));
      }
    } else {
      notification.destroy();
      notification.info({
        message: 'Bạn cần phải đăng nhập để sử dụng đầy đủ tính năng',
        btn: (
          <Button
            onClick={() => {
              window.location.href = '/signin';
            }}>
            Đăng nhập
          </Button>
        ),
        closable: false,
      });
    }
  };
  return (
    <Modal
      styles={{
        content: {
          padding: 10,
          width: 360,
        },
      }}
      style={cellPosition}
      mask={false}
      closable={false}
      loading={loadingStock}
      open={show}
      onCancel={onHide}
      afterClose={() => {
        setStockInfo(null);
        setValue('');
      }}
      footer={[]}>
      <Fragment>
        <Flex
          justify="center"
          style={{backgroundColor: 'rgb(159,34,58)', borderTopLeftRadius: 8, borderTopRightRadius: 8}}>
          <Title level={4} style={{color: 'white'}}>
            {selectedStockCode}
          </Title>
        </Flex>
        <Flex vertical align="center" style={{backgroundColor: 'rgba(235, 235, 235, 1)', paddingTop: 10}}>
          <Title level={4}>
            {convertMoney(
              findExcelObjectByMultiKey(stockInfo?.properties || [], [
                'gia-full-vatkpbt',
                'tong-gia-tri-can-ho-gom-vatkbpt',
                'tong-gia-chua-vat-kpbt'
              ])?.value,
            )}
          </Title>
          <Paragraph>Giá đã bao gồm VAT & KPBT</Paragraph>
          {projectDetail && projectDetail.linkZalo ? (
            <Flex align="center" justify="center" gap={8}>
              <MessageOutlined />
              <Link to={projectDetail?.linkZalo || ''} target="_blank">
                Liên hệ Admin
              </Link>
            </Flex>
          ) : (
            <></>
          )}

          {findExcelObjectContainKey(stockInfo?.properties || [], 'csbh')?.value ? (
            <Paragraph style={{color: 'rgb(159,34,58)', textAlign: 'center'}}>
              Chính sách bảo hành áp dụng: {findExcelObjectContainKey(stockInfo?.properties || [], 'csbh')?.value}
            </Paragraph>
          ) : (
            <Paragraph style={{color: 'rgb(159,34,58)', textAlign: 'center'}}>
              Chưa cập nhật chính sách bảo hành
            </Paragraph>
          )}
        </Flex>
        <Row gutter={8} style={{backgroundColor: 'rgba(235, 235, 235, 1)', paddingBlock: 10, margin: 0}}>
          {stockInfo && stockInfo.properties ? (
            stockInfo.properties.map((item, index) => {
              if (item.key.includes('gia') && !item.key.includes('gia-full') && !item.key.includes('tong'))
                return item.value ? (
                  <Col span={8}>
                    <Flex vertical align="center" gap={5} key={index}>
                      <Text style={{textAlign: 'center'}}>{item.label}</Text>
                      <Title level={5}>{convertMoney(item.value)}</Title>
                    </Flex>
                  </Col>
                ) : (
                  <></>
                );
            })
          ) : (
            <></>
          )}
        </Row>
        <Row gutter={8} style={{backgroundColor: 'rgba(67, 89, 113, 0.05)', paddingBlock: 10, margin: 0}}>
          {stockInfo && stockInfo.properties ? (
            stockInfo.properties.map((item, index) => {
              if (
                !item.key.includes('gia') &&
                !item.key.includes('ma-can') &&
                !item.key.includes('note') &&
                !item.key.includes('anh') &&
                !item.key.includes('csbh') &&
                !item.key.includes('ptg')
              )
                return (
                  <Col span={8}>
                    <Flex vertical align="center" gap={5} key={index}>
                      <Text>{item.label}</Text>
                      {item.value ? (
                        <Title level={5}>{Number.isNaN(item.value) ? item.value : convertMoney(item.value, '')}</Title>
                      ) : (
                        <Text>Chưa cập nhật</Text>
                      )}
                    </Flex>
                  </Col>
                );
            })
          ) : (
            <></>
          )}
        </Row>
        <Flex vertical style={{padding: 10}}>
          {stockInfo && stockInfo.properties && (checkIsAdmin() || checkIsSuperAdmin()) ? (
            stockInfo.properties.map((item, index) => {
              if (item.key.includes('note') || item.key.includes('ghi-chu')) {
                if (item.value) {
                  return (
                    <Flex align="center" gap={10} key={index}>
                      <HomeOutlined />
                      <Paragraph style={{margin: 0}}>{item.value}</Paragraph>
                    </Flex>
                  );
                } else {
                  return <></>;
                }
              }
            })
          ) : (
            <></>
          )}
          <div>
            {stockInfo && stockInfo.properties ? (
              stockInfo.properties.map((item, index) => {
                if (item.textLink) {
                  return (
                    <Button
                      onClick={() => onLinkClick(item.textLink || '', item.label)}
                      icon={<AntDesignOutlined />}
                      type="link">
                      {item.label}
                    </Button>
                  );
                }
              })
            ) : (
              <></>
            )}
          </div>
        </Flex>
        <Divider />
      </Fragment>
    </Modal>
  );
};
export default DetailModal;
