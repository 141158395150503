import {ReactNode} from 'react';
import OTPScreen from 'src/screens/authentication/otp';
import SignInScreen from 'src/screens/authentication/signIn';
import SignUpScreen from 'src/screens/authentication/signUp';
import AddBlockScreen from 'src/screens/block/addBlock';
import BlockInfoScreen from 'src/screens/block/blockInfo';
import UpdateBlockScreen from 'src/screens/block/updateBlock';
import AddBuildingScreen from 'src/screens/building/addBuilding';
import BuildingInfo from 'src/screens/building/buildingInfo';
import UpdateBuildingScreen from 'src/screens/building/updateBuilding';
import DashboardScreen from 'src/screens/dashboard';
import ProfileScreen from 'src/screens/profile';
import AddProjectScreen from 'src/screens/project/addProject';
import ProjectInfo from 'src/screens/project/projectInfo';
import UpdateProjectScreen from 'src/screens/project/updateProject';
import {ROUTE_CONFIG} from './config';
import Decentralization from 'src/screens/decentralization';
import UserEventManage from 'src/screens/userEventManage';

interface IScreen {
  path: string;
  element: ReactNode;
}
export const AUTHEN_ROUTES: IScreen[] = [
  {
    path: ROUTE_CONFIG.SIGNIN,
    element: <SignInScreen />,
  },
  {
    path: ROUTE_CONFIG.SIGNUP,
    element: <SignUpScreen />,
  },
  {
    path: ROUTE_CONFIG.OTP,
    element: <OTPScreen />,
  },
];
export const MAIN_ROUTES: IScreen[] = [
  {
    path: ROUTE_CONFIG.HOME,
    element: <DashboardScreen />,
  },
  {
    path: ROUTE_CONFIG.PROFILE,
    element: <ProfileScreen />,
  },
  {
    path: ROUTE_CONFIG.DECENTRALIZATION,
    element: <Decentralization />,
  },
  {
    path: ROUTE_CONFIG.USER_EVENT,
    element: <UserEventManage />,
  },
  {
    path: `${ROUTE_CONFIG.ADD_BUILDING}/:projectId/:blockId`,
    element: <AddBuildingScreen />,
  },
  {
    path: `${ROUTE_CONFIG.BUILDING_INFO}/:id`,
    element: <BuildingInfo />,
  },
  {
    path: `${ROUTE_CONFIG.UDPATE_BUILDING}/:id`,
    element: <UpdateBuildingScreen />,
  },
  {
    path: ROUTE_CONFIG.ADD_PROJECT,
    element: <AddProjectScreen />,
  },
  {
    path: `${ROUTE_CONFIG.PROJECT_INFO}/:id`,
    element: <ProjectInfo />,
  },
  {
    path: `${ROUTE_CONFIG.UDPATE_PROJECT}/:id`,
    element: <UpdateProjectScreen />,
  },
  {
    path: `${ROUTE_CONFIG.ADD_BLOCK}/:projectId`,
    element: <AddBlockScreen />,
  },
  {
    path: `${ROUTE_CONFIG.BLOCK_INFO}/:id`,
    element: <BlockInfoScreen />,
  },
  {
    path: `${ROUTE_CONFIG.UPDATE_BLOCK}/:id`,
    element: <UpdateBlockScreen />,
  },
];
